/*
 * @Author: 李淳
 * @Date: 2020-06-24 15:37:52
 * @LastEditors: liqingqing
 * @LastEditTime: 2021-08-16 20:20:55
 * @Description: 面向fetch请求切面，对请求成功失败的附加处理；
 */
import { Modal, notification } from 'antd';
import { getGlobalIntl } from 'i18n/i18n';
import isEmpty from 'lodash/isEmpty';
import longan from 'longan-sdk';
import React from 'react';
import { fork, put, take } from 'redux-saga/effects';
import { commonActions } from 'utils/connect';
import {
  FETCH_FULFILLED_SUFFIX,
  FETCH_REJECTED_SUFFIX,
  POST_ACTION_PREFIX,
  USER_NOT_LOGIN_ERRNO,
  WITH_SUCCESS_CALLBACK,
} from 'utils/constants';
import history from 'utils/history';
import globalMessages from 'utils/messages';
import { isInFrame } from 'utils/utils';
import { actions } from './globalSlice';

const type = process.env.PROJECT_ENV ?? 'test';

function* fetchSuccessSaga(action: IRedux.TAction): Generator {
  const golbalIntl = getGlobalIntl();
  const result = action?.payload;

  if (result && result.errno === 0) {
    // 【成功】
    if (action.type.indexOf(POST_ACTION_PREFIX) > -1) {
      // 预设只有post接口在正确时显示信息
      notification.success({
        message: golbalIntl.formatMessage(globalMessages.operateSuccess),
      });
    }
    // action带了自动刷新的标记，则dispath一个refresh的操作
    if (action.type.indexOf(WITH_SUCCESS_CALLBACK) > -1) {
      yield put(commonActions.refresh());
    }
  } else {
    notification.error({
      message: golbalIntl.formatMessage(globalMessages.networkError),
    });
    longan.dispatch({
      event_name: 'network_offline',
      event_desc: '网络错误',
      event_type: 'selfDefine',
    });
    yield put(actions.operateError(new Error(JSON.stringify(action))));
  }
}

function* fetchFailSaga(action: IRedux.TAction): Generator {
  const golbalIntl = getGlobalIntl();
  const result = action?.payload;
  if (result && result.errno) {
    if (result.errno === USER_NOT_LOGIN_ERRNO) {
      // 未登录，跳转登录
      if (isInFrame()) {
        Modal.destroyAll();
        Modal.error({
          title: golbalIntl.formatMessage(globalMessages.userInfoError),
        });
        return;
      }
      // 跳转登录
      const { pathname, search, hash } = window.location;
      // 编码一下，否则会出现某些特定search参数被cas给捕获到导致的意外情况
      const origin = encodeURIComponent(`${pathname}${search}${hash}`);
      // 测试环境没对接cas，跳转没用，就不跳转了，直接停留在localhost粘贴token就可以了
      if (pathname !== '/login' && type !== 'test') {
        history.push(`/login?origin=${origin}`);
      }
      return;
    }
    const errMsg = React.createElement(
      'div',
      null,
      result.errmsg ? result.errmsg : golbalIntl.formatMessage(globalMessages.operateFailed),
    );
    const logId =
      result.lid || result.logId
        ? React.createElement(
            'div',
            null,
            golbalIntl.formatMessage(globalMessages.errorLogId, { logId: result.lid || result.logId }),
          )
        : null;
    notification.error({
      // 不同于请求成功，所有的请求都会暴露失败消息
      message: React.createElement('div', null, [errMsg, logId]),
    });
    yield put(actions.operateError(new Error(JSON.stringify(action))));
    return;
  }
  notification.error({
    message: golbalIntl.formatMessage(globalMessages.networkError),
  });
  longan.dispatch({
    event_name: 'network_offline',
    event_desc: '网络错误',
    event_type: 'selfDefine',
  });
  yield put(actions.operateError(new Error(JSON.stringify(action))));
}

// The watcher: watch actions and coordinate worker tasks
function* watchFetchResult() {
  while (true) {
    const reg = new RegExp(`${FETCH_FULFILLED_SUFFIX}|${FETCH_REJECTED_SUFFIX}`);
    // eslint-disable-next-line no-loop-func
    const action: IRedux.TAction = yield take((data: IRedux.TAction) => !isEmpty(data.type.match(reg)));
    if (action.type.indexOf(FETCH_REJECTED_SUFFIX) > -1) {
      yield fork(fetchFailSaga, action);
    } else {
      yield fork(fetchSuccessSaga, action);
    }
  }
}

export default watchFetchResult;
