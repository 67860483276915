import { Dropdown, Menu as AntdMenu, Tooltip } from 'antd';
import authMap from 'configs/auth.conf';
import { i18nConf } from 'configs/base.conf';
// import { gotoPass } from 'configs/pass.conf';
import Auth from 'containers/AuthController';
import usePageJump from 'hooks/usePageJump';
import { changeI18nEnv } from 'i18n/i18n';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import enMessagePng from 'static/images/ic_en_message@2x.png';
import faqPng from 'static/images/ic_faq_center@2x.png';
import announcePng from 'static/images/ic_info_announce@2x.png';
import hidePng from 'static/images/ic_info_hide.png';
import seePng from 'static/images/ic_info_see@2x.png';
import fengxiangbiaoPng from 'static/images/img_fengxiangbiao_logo.png';
import personagePng from 'static/images/nav_personage@3x.png';
import { actions } from 'store/globalSlice';
import { selectDesensitizeConf, selectDesensitizeFlagMap, selectLoginUserInfo } from 'store/selectors';
import messages, { headerBarTips } from 'utils/messages';
import { isInFrame } from 'utils/utils';

import MultipleTabs from '../components/MultipleTabs';
import { HeaderWrapper } from '../components/Wrappers';

const { useEffect, useState } = React;

const projectEnv = process.env.PROJECT_ENV;

function Header() {
  const intl = useIntl();
  const pageJump = usePageJump();
  const userInfo = useSelector(selectLoginUserInfo);
  const desensitizeConf = useSelector(selectDesensitizeConf);
  const desensitizeFlagMap = useSelector(selectDesensitizeFlagMap);
  // 页面是否展示脱敏切换按钮
  const [isShowDesensitizeSwitch, setIsShowDesensitizeSwitch] = useState(false);
  const [desensitizeFlag, setDesensitizeFlag] = useState('0');
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const theme = JSON.parse(localStorage.getItem('theme') || '{}');
  //   (window as any).less
  //     .modifyVars(theme);
  // }, []);

  /**
   * 判断当前页面是否展示脱敏切换按钮
   * @description 若isForcedDesensitized为true，则不会显示脱敏切换；否则需要看isEdit是否为true
   * @param pathUrl 页面路径
   * @returns boolean
   */
  const checkIsShowDesensitizeSwitch = (pathUrl: string) => {
    if (Object.keys(desensitizeFlagMap).includes(pathUrl)) {
      return true;
    }
    return false;
  };

  // 路由、desensitizeConf变化时增加对应的页面脱敏状态
  useEffect(() => {
    const pathUrl = pathname.split('?')[0];
    const path = pathname.split('/:')[0];
    if (
      !Object.keys(desensitizeFlagMap).includes(pathUrl) &&
      desensitizeConf?.[path]?.isEdit &&
      !desensitizeConf?.[path]?.isForcedDesensitized
    ) {
      dispatch(actions.updateDesensitizePageFlagMap({ [pathUrl]: '0' }));
    }
    setIsShowDesensitizeSwitch(checkIsShowDesensitizeSwitch(pathUrl));
    setDesensitizeFlag(desensitizeFlagMap[pathUrl]);
  }, [pathname, desensitizeConf, desensitizeFlagMap]);

  useEffect(() => {
    const pathUrl = pathname.split('?')[0];
    setDesensitizeFlag(desensitizeFlagMap[pathUrl]);
  }, [JSON.stringify(desensitizeFlagMap)]);

  // 页面脱敏切换
  const handlePageDesensitizeConfSwitch = () => {
    const path = pathname.split('?')[0];
    const currentDesensitize = desensitizeFlag === '1' ? '0' : '1';
    dispatch(
      actions.updateDesensitizePageFlagMap({
        [path]: currentDesensitize,
      }),
    );
  };

  const goToAnnounce = () => {
    const newPath = '/publishManage/announceManage';
    pageJump(newPath);
  };

  const goToHelpCenter = () => {
    const newPath = '/helpCenter';
    pageJump(newPath);
  };

  const gotoFengxiangbiao = () => {
    const path =
      projectEnv === 'online'
        ? 'http://scc-wpm.sf-express.com/#/fengjingtai/BIM2?fromuser=127'
        : 'http://scc-wpm.sf-express.com/#/fengjingtai/BIM2?fromuser=127';
    window.open(path, '_blank');
  };

  const handleLogout = () => {
    history.push('/logout');
    // gotoPass('logout');
  };

  return (
    <HeaderWrapper>
      <div className="menu-tabs">
        <MultipleTabs />
      </div>
      {/* 右侧操作区域 */}
      <div className="global-action" style={{ width: 'auto' }}>
        {/* 丰向标 */}
        <Auth authCode={authMap.AUTH_PAGE_FENGXIANGBIAO}>
          <Tooltip title={intl.formatMessage(headerBarTips.wpm)}>
            <img src={fengxiangbiaoPng} alt="wpm" onClick={gotoFengxiangbiao} />
          </Tooltip>
        </Auth>
        {/* 帮助中心 */}
        <Tooltip title={intl.formatMessage(headerBarTips.help_center)}>
          <img src={faqPng} alt="faq" onClick={goToHelpCenter} />
        </Tooltip>
        {/* 国际化 */}
        {i18nConf.enable && (
          <Tooltip title={intl.formatMessage(headerBarTips.lang_change)}>
            <img src={enMessagePng} alt="translate" onClick={() => changeI18nEnv()} />
          </Tooltip>
        )}
        {/* 公告管理 */}
        <Auth authCode={authMap.AUTH_PAGE_AnnounceManage}>
          <Tooltip title={intl.formatMessage(headerBarTips.view_announcements)}>
            <img src={announcePng} alt="announce" onClick={goToAnnounce} />
          </Tooltip>
        </Auth>
        {isShowDesensitizeSwitch && (
          <Tooltip placement="bottomRight" title={intl.formatMessage(headerBarTips.desensitize_switch)}>
            <img
              src={desensitizeFlag === '1' ? seePng : hidePng}
              alt="desensitize"
              onClick={handlePageDesensitizeConfSwitch}
            />
          </Tooltip>
        )}
        {isInFrame() ? null : (
          <Dropdown
            trigger={['click']}
            overlay={
              <AntdMenu>
                <AntdMenu.Item key="code">
                  <span>{userInfo?.sfucode || '-'}</span>
                </AntdMenu.Item>
                <AntdMenu.Divider />
                <AntdMenu.Item key="logout" onClick={handleLogout}>
                  <span>{intl.formatMessage(messages.logout)}</span>
                </AntdMenu.Item>
              </AntdMenu>
            }
          >
            <img src={personagePng} alt="avatar" />
          </Dropdown>
        )}
      </div>
    </HeaderWrapper>
  );
}

export default Header;
